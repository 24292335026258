<template>
	<div class="out">
		<div class="header">
			<div class="logo Title">

				<div class="Chinese"><img src="../assets/img/return_index.png" class="return1" alt=""
						@click="$router.back()" />{{ $t('main.tpc') }} <span v-show="hasCG && id" style="color:#999">{{
							$t('main.draft') }}</span>

				</div>
			</div>
			<div class="right-icon">
				<img src="../assets/img/logo-icon.png" alt="">
				<div class="cc-wc">
					{{ $t('main.titleCenter') }}
				</div>
			</div>
		</div>
		<div class="content" style="padding-top: 0.26rem;" >
			<!-- <div class="zb_count">
				<div class="left">
					<div>珍宝存证数量上限：<span>{{ max_auth_count }} 个</span> </div>
					<div>已使用{{ now_auth_count }} 个</div>
				</div>
				<div class="buy_count" @click="showMask">购买数量</div>
			</div> -->
			<div class="zb-edit" >
				<div class="zb-pic zb-edit-item">
					<div class="zb-edit-item-top">
						<div class="title-ltfe"><img src="../assets/img/zb-pic-icon.png" alt="">{{ $t('main.trphoto') }}
						</div>
						<div class="title-right">{{ $t('main.fidmia10') }}<span class="red">{{ $t('main.required') }}</span>
						</div>
					</div>
					<div class="">
						<div class="sc-btn">

							<div class="sc-btn-item" @click="getPhoto()" v-for="(item, index) in  picList" :key="item.url">

								<div><img :src="item.url" alt="">
									<div class="delete" @click.stop="delete_IMG(index)">X</div>
								</div>
								<div v-show="item.status == 'loading'" class="loading_i">
									<van-loading type="spinner" color="#fff" size="0.24rem" vertical>{{ $t('main.uploading')
									}}</van-loading>
								</div>
							</div>

							<div class="sc-btn-item" @click="getPhoto()" v-for="item in (5 - picList.length)" :key="item">
								<div>+</div>
							</div>
						</div>
					</div>
				</div>
				<div class="zb-pic zb-edit-item">
					<div class="zb-edit-item-top">
						<div class="title-ltfe"><img src="../assets/img/ds-bj-icon2.png" alt="">{{ $t('main.trname') }} <img
								class="question" v-show="help.edit_goods_name" @click="showHelpContent('edit_goods_name')"
								src="../assets/img/question.png" alt="" srcset="" /></div>
						<div class="title-right">&lt;24{{ $t('main.l20cc') }};<span class="red">{{ $t('main.required')
						}}</span>
						</div>
					</div>
					<div class="zb-edit-item-bottom">
						<input type="text" :placeholder="$t('main.pitrname')" @keyup="name = name.replace(/^\s+/g, '')"
							v-model="name" maxlength="24" />
					</div>
					<div class="err" v-show="key_test == 'name'">{{ $t('main.csenword') }}{{ mgc }}</div>
				</div>
				<div class="zb-pic zb-edit-item">
					<div class="zb-edit-item-top">
						<div class="title-ltfe"><img src="../assets/img/zb-pic-icon2.png" alt="">{{ $t('main.trdata') }}
							<img class="question" v-show="help.edit_goods_data" @click="showHelpContent('edit_goods_data')"
								src="../assets/img/question.png" alt="" srcset="" />
						</div>
						<div class="title-right">&lt;50{{ $t('main.l20cc') }};<span class="red">{{ $t('main.required')
						}}</span>
						</div>
					</div>
					<div class="zb-edit-item-bottom " v-for="( item, index) in info" :key="index">

						<input type="text" :placeholder="$t('main.pitrdata')" onkeyup="value=value.replace(/^\s+/g,'')"
							v-model="info[index]" maxlength="50" />
						<div class="delete_e" v-if="false" @click="delete_info(index)">X</div>
						<div class="err1" v-show="key_test == 'info.' + index">{{ $t('main.csenword') }}{{ mgc }}</div>
					</div>
					<div class="zb-edit-item-bottom ">
						<div class="add-input" @click="addInfo"><span class="add-icon">+</span><span>{{ $t('main.addrow')
						}}</span></div>
					</div>
				</div>
				<div class="zb-pic zb-edit-item">
					<div class="zb-edit-item-top">
						<div class="title-ltfe"><img src="../assets/img/zb-pic-icon3.png" alt="">{{ $t('main.timeofcrea') }}
						</div>
						<div class="title-right">
							<span class="red">{{ $t('main.required') }}</span>
						</div>
					</div>
					<div class="zb-edit-item-bottom" @click="weDate">
						<input type="text" readonly :placeholder="$t('main.pccoftr')" v-model="manufacturing_date">
					</div>
				</div>
				<div class="zb-pic zb-edit-item">
					<div class="zb-edit-item-top">
						<div class="title-ltfe"><img src="../assets/img/ds-bj-icon4.png" alt="">{{ $t('main.trdes') }} <img
								class="question" v-show="help.edit_goods_describe"
								@click="showHelpContent('edit_goods_describe')" src="../assets/img/question.png" alt=""
								srcset="" /></div>
						<div class="title-right">&lt;2000{{ $t('main.l20cc') }};<span class="red">{{ $t('main.required')
						}}</span></div>
					</div>
					<div class="zb-edit-item-bottom">

						<textarea v-model="description" @keyup="description = description.replace(/^\s+/g, '')"
							:placeholder="$t('main.pbintroutr')" maxlength="2000"></textarea>
					</div>
					<div class="err" v-show="key_test == 'description'">{{ $t('main.csenword') }}{{ mgc }}</div>
				</div>
				<div class="zb-pic zb-edit-item">
					<div class="zb-edit-item-top">
						<div class="title-ltfe"><img src="../assets/img/ds-bj-icon5.png" alt="">{{ $t('main.trhonor') }}
							<img class="question" v-show="help.edit_goods_honor"
								@click="showHelpContent('edit_goods_honor')" src="../assets/img/question.png" alt=""
								srcset="" />
						</div>
						<div class="title-right">
							{{ $t('master.honor50') }}
						</div>
					</div>
					<div class="zb-edit-item-bottom">
						<textarea v-model="honor" @keyup="honor = honor.replace(/^\s+/g, '')"
							:placeholder="$t('main.pitrhbhon')" rows="100" cols="50"></textarea>
					</div>
					<div class="err" v-show="key_test == 'honor'">{{ $t('main.csenword') }}{{ mgc }}</div>
				</div>

				<div class="zb-pic zb-edit-item">
					<div class="zb-edit-item-top">
						<div class="title-ltfe">
							<div class="ds-left-icon">
								<img src="../assets/img/certificate.png" alt="">
								{{ $t('main.trc') }}
							</div>
						</div>
						<div class="title-right">
							{{ $t('master.upce') }}
						</div>
					</div>
					<div class="creti-box" v-for="(item, index) in certificate_info" :key="index">
						<div class="zs-title">{{ $t('main.certificate') }}{{ index + 1 }}</div>
						<img src="../assets/img/close-icon.png" class="concan" @click="concan(index)" alt="" />
						<div class="certi-add-btn" @click="addCretiPic(index)">
							<img v-if="item.pic" :src="item.pic" alt="">
							<div class="add-btn-icon" v-else>+</div>
							<div class="loading_icon" v-show="load_list[index]">
								<van-loading type="spinner" color="#fff" size="0.24rem" vertical>{{ $t('main.uploading')
								}}</van-loading>
							</div>
						</div>
						<div class="zb-edit-item-content">
							<input type="text" maxlength="20" @keyup="item.content = item.content.replace(/^\s+/g, '')"
								:placeholder="$t('main.peacename')" v-model="item.content" />
						</div>
					</div>
					<div class="add-btn" @click="addBtn" v-if="certificate_info.length <= 9">
						<span class="add-icon">+</span><span>{{ $t('main.newcer') }}</span>
					</div>
				</div>
				<div class="zb-pic zb-edit-item">
					<div class="zb-edit-item-top">
						<div class="title-ltfe"><img src="../assets/img/ds-bj-icon3.png" alt="">{{ $t('main.mv') }} <img
								class="question" v-show="help.edit_goods_market_valuation"
								@click="showHelpContent('edit_goods_market_valuation')" src="../assets/img/question.png"
								alt="" srcset="" /></div>
						<div class="title-right">
							{{ $t('master.less11') }}<span class="red">{{ $t('main.required') }}</span>
						</div>
					</div>
					<div class="zb-edit-item-bottom rmb-icon-box">
						<span class="rmb-icon">￥</span>
						<input type="number" oninput="if(value.length>11)value=value.slice(0,11)"
							onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
							:placeholder="$t('main.petmest')" v-model="price" maxlength="11" />
					</div>
				</div>
				<div class="agree-box" @click="changeLevel">

					<img class="question1" v-show="help.edit_goods_levels" @click.stop="showHelpContent('edit_goods_levels')"
						src="../assets/img/question.png" alt="" srcset="" />
					<div>{{ $t('main.trlevel') }}{{
						level_list.length ? level_list.find((item) => {
							return item.id ==
								level_id
						}) ? level_list.find((item) => { return item.id == level_id }).name : '' : ''
					}}</div>
					<img class="picker_img" src="../assets/img/return_index.png" alt="">
				</div>
				<!-- <div class="agree-box1" v-show="is_xy_show">
					<input @input="checkedFlag1" type="checkbox" value="" id="agree-select1" :checked="isKnow" />
					<label id="" for="agree-select1"> {{$t('main.agreeC')}} </label>
					<span class="blue_xy" @click.stop="showContent">《{{ xy.title }}》</span>
				</div> -->

				<!-- <div class="tips">
					<img src="../assets/img/costTips.png" />
					<div>
						注意：珍宝转让给他人时会生成<span>平台存证证书</span>，需支付<span>存证费用</span>；可自行决定<span>支付方</span>。
					</div>
				</div> -->
				<div class="" style="display: flex;justify-content: space-between;align-items: center;">
					<div class="yulan_btn" @click="preview">
						{{ $t('main.preview') }}
					</div>
					<div class="submint-info" @click="tijiao">{{ $t('main.submit') }}</div>
				</div>
			</div>
			<img style="display:none" id="icon_logo" src="../assets/img/sy_logo.png" alt="">
			<img style="display:none" id="shuiyin" src="../assets/img/logo2.png" alt="">

			<!-- <div class="count_mask" v-if="auth_mask" @click="showMask">
				<div class="count_pop" @click.stop>
					<div class="title">增加珍宝存证数量</div>
					<div class="count_list">
						<div class="count_items" @click="now_auth_index = index"
							:class="now_auth_index == index ? 'active' : ''" v-for="(item, index) in auth_count_list">
							<div class="num">存证数量+{{ item.ga_count }}</div>
							<div class="price">￥<span>{{ item.ga_money }}</span></div>
							<div class="count">每个仅需￥{{ item.ga_average }}</div>
						</div>
					</div>
					<div class="count_tips">
						支付￥{{ auth_count_list[now_auth_index].ga_money }} 新增珍宝存证数量 {{
							auth_count_list[now_auth_index].ga_count }} 个
					</div>
					<div class="count_bottom">
						<div class="count_button" @click="pay_count">
							<div>
								￥{{ auth_count_list[now_auth_index].ga_average }}<span>/1个</span>
							</div>
							<div class="">确认并支付</div>
						</div>
					</div>
				</div>
			</div> -->
		</div>

	</div>
</template>

<script>
import { Dialog } from 'vant';
export default {
	data() {
		return {
			goRegFlag: false,
			description: '',
			picList: [

			],
			pictures: [],
			name: "",
			info: [""],
			manufacturing_date: '',
			honor: '',
			price: '',
			id: '',
			status: '',
			isKnow: true,
			xy: {
				title: '',
				content: ''
			},
			level_id: 1,
			level_list: [],
			picker: [],
			isFrist: true,
			beforeZB: {
				pictures: '',
				info: [""],
				manufacturing_date: '',
				description: '',
				honor: '',
				price: 0,
				name: '',
				level_id: ''
			},
			key_test: '',
			mgc: '',
			is_xy_show: false,
			hasCG: true,
			help: {
				edit_goods_name: null,
				edit_goods_data: null,
				edit_goods_describe: null,
				edit_goods_honor: null,
				edit_goods_market_valuation: null,
				edit_goods_levels: null
			},
			loading: false,
			certificate_info: [{
				pic: "",
				content: "",
			}],
			load_list: [false],
			audio_type: 0,
			draftSasucc: "",
			max_auth_count: 0,
			now_auth_count: 0,
			auth_count_list: [],
			now_auth_index: 0,
			auth_mask: false,
			oldData:{}
		}
	},
	
	async mounted() {

		this.draftSasucc = this.$t('main.draftSasucc')
		
		if (!localStorage.getItem('helpinfo') || localStorage.getItem('helpinfo') == '{}') {
			this.help = {
				edit_goods_name: null,
				edit_goods_data: null,
				edit_goods_describe: null,
				edit_goods_honor: null,
				edit_goods_market_valuation: null,
				edit_goods_levels: null
			}
		} else {
			if (this.$i18n.locale == 'en') {
				let data = JSON.parse(localStorage.getItem('helpinfo'))
				Object.keys(data).forEach((item) => {
					if (item.indexOf('_intl') != -1) {
						let label = item.split('_intl')[0]
						if (data[item]) {
							data[label] = data[item]
						}
					}
				})
				this.help = data
			} else {
				this.help = JSON.parse(localStorage.getItem('helpinfo'))
			}
		}
		// this.axios.post('/goods/getGoodsAuthRuleList').then((res) => {
		// 	this.auth_count_list = res.data.data.list
		// })
		// this.axios.post('/artist/detail', { id: localStorage.getItem("art_id") }).then((res) => {
		// 	this.max_auth_count = parseInt(res.data.data.all_auth_count)
		// 	this.now_auth_count = parseInt(res.data.data.auth_count)
		// })
		this.axios.get('/goods/level').then((res) => {

			this.level_list = res.data.data.list
			let list = []
			for (let i = 0; i < this.level_list.length; i++) {
				if (this.$i18n.locale == 'en') {
					if (this.level_list[i].name_intl) {
						this.level_list[i].name = this.level_list[i].name_intl
					}
				}
				list.push({
					label: `<div style="display:flex;justify-content: space-between;box-sizing: border-box;padding:0 0.15rem"> <span style="color:${this.level_list[i].color}">${this.level_list[i].name}</span> <span>${this.$t('main.dccp', { N: this.level_list[i].auth_fee })}</span> </div>`,
					value: this.level_list[i].id
				})
			}
			this.picker = list
		})
		if (this.$route.query.id) {
			this.hasCG = false
			this.id = this.$route.query.id
			this.status = this.$route.query.status
			this.isKnow = true
			if (this.$route.query.status == 1) {
				let newdata = ''
				await this.axios.post('common/encrypt',{goods_id: this.id}).then((res)=>{
					newdata = res.data.data.data_encrypted
				})
				this.axios.get('/goods/info?data=' + newdata).then((res) => {
					this.picList = []
					for (let i = 0; i < res.data.data.pictures.length; i++) {
						this.picList.push(
							{
								url: (res.data.data.pictures[i] + '?width=60').replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol),
								status: 'done',
								message: this.$t('main.upsucc'),
							}
						)
					}
					this.oldData = res.data.data
					this.pictures = res.data.data.pictures
					this.info = res.data.data.info
					this.manufacturing_date = res.data.data.manufacturing_date
					this.description = res.data.data.description
					this.honor = res.data.data.honor
					this.price = parseInt(res.data.data.price)
					this.name = res.data.data.name
					this.level_id = res.data.data.level_id
					this.certificate_info = res.data.data.certificate_info ? res.data.data.certificate_info : [{
						pic: "",
						content: "",
					}],
						this.audio_type = res.data.data.audio_type
					this.beforeZB = JSON.parse(JSON.stringify(res.data.data))
					this.beforeZB.price = parseInt(this.beforeZB.price)
					for (let i = 0; i < this.certificate_info.length; i++) {
						this.load_list.push(false);
					}

				})
			} else {
				this.axios.get('/goods/edit/' + this.id).then((res) => {

					this.picList = []
					for (let i = 0; i < res.data.data.pictures.length; i++) {
						this.picList.push(
							{
								url: (res.data.data.pictures[i] + '?width=60').replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol),
								status: 'done',
								message: this.$t('main.upsucc'),
							}
						)
					}
					this.pictures = res.data.data.pictures
					this.info = res.data.data.info
					this.manufacturing_date = res.data.data.manufacturing_date
					this.description = res.data.data.description
					this.honor = res.data.data.honor
					this.price = parseInt(res.data.data.price)
					this.name = res.data.data.name
					this.level_id = res.data.data.level_id
					this.certificate_info = res.data.data.certificate_info ? JSON.parse(res.data.data.certificate_info) : [{
						pic: "",
						content: "",
					}]
					this.beforeZB = JSON.parse(JSON.stringify(res.data.data))
					this.beforeZB.price = parseInt(this.beforeZB.price)
					this.beforeZB.certificate_info = this.beforeZB.certificate_info ? JSON.parse(this.beforeZB.certificate_info) : []
					for (let i = 0; i < this.certificate_info.length; i++) {
						this.load_list.push(false);
					}
				})
			}

		} else {
			this.axios.get('/goods/draft').then((res) => {
				if (JSON.stringify(res.data.data) != "{}") {
					this.picList = []

					this.pictures = res.data.data.pictures.length ? res.data.data.pictures[0] ? res.data.data.pictures : [] : []
					for (let i = 0; i < this.pictures.length; i++) {
						this.picList.push(
							{
								url: (this.pictures[i] + '?width=60').replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol),
								status: 'done',
								message: this.$t('main.upsucc'),
							}
						)
					}
					this.info = res.data.data.info
					this.manufacturing_date = res.data.data.manufacturing_date
					this.description = res.data.data.description
					this.honor = res.data.data.honor
					this.price = parseInt(res.data.data.price) == 0 ? '' : parseInt(res.data.data.price)
					this.name = res.data.data.name
					this.level_id = res.data.data.level_id
					this.certificate_info = res.data.data.certificate_info ? JSON.parse(res.data.data.certificate_info) : [{
						pic: "",
						content: "",
					}]
					this.beforeZB = JSON.parse(JSON.stringify(res.data.data))
					this.beforeZB.pictures = this.beforeZB.pictures.length ? this.beforeZB.pictures[0] ? this.beforeZB.pictures : [] : []
					this.beforeZB.price = parseInt(this.beforeZB.price) == 0 ? '' : parseInt(this.beforeZB.price)
					this.beforeZB.info = this.beforeZB.info.length ? this.beforeZB.info[0] ? this.beforeZB.info : [] : []
					this.beforeZB.certificate_info = this.beforeZB.certificate_info ? JSON.parse(this.beforeZB.certificate_info) : []
					this.id = res.data.data.id
					this.status = 2
					for (let i = 0; i < this.certificate_info.length; i++) {
						this.load_list.push(false);
					}
				}
			})
		}
		let newdata = ''
			await this.axios.post('common/encrypt',{type:2 + (this.$i18n.locale == 'en' ? 6 : 0)}).then((res)=>{
				newdata = res.data.data.data_encrypted
			})
		this.axios.post('/agreement/detail', { data:newdata }).then((res) => {

			if (!res.data.data.title || !res.data.data.content) {
				this.isKnow = true
				this.is_xy_show = false

				return
			}
			this.is_xy_show = true
			this.xy.title = res.data.data.title
			this.xy.content = res.data.data.content
		})


		let url = location.protocol + '//' + location.host
		let param = this.$route.fullPath
		this.axios.post('/auth/wechatConfig', {
			url: url + this.$store.state.jssdkUrl,
			jsApiList: ['chooseImage', 'getLocalImgData']
		}).then((res) => {

			let json = JSON.parse(res.data.data.data)

			wx.config({
				debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
				appId: json.appId, // 必填，公众号的唯一标识
				timestamp: json.timestamp, // 必填，生成签名的时间戳
				nonceStr: json.nonceStr, // 必填，生成签名的随机串
				signature: json.signature, // 必填，签名
				jsApiList: json.jsApiList // 必填，需要使用的 JS 接口列表
			})

		})
		// window.addEventListener("popstate",()=>{
		// 	this.disableBrowserBack()
		// },false)
	},
	methods: {
		disableBrowserBack() {
			let certiFlag = 0
			for (let i = 0; i < this.certificate_info.length; i++) {
				if (this.certificate_info[i].pic || this.certificate_info[i].content) {
					certiFlag = 1;
					break;
				}
			}

			var infoList = this.info.filter((item) => {
				return item.trim() != ""
			})
			if (!(this.name || infoList[0] || this.pictures[0] || this.manufacturing_date || this.description || this.price || certiFlag)) {

				// this.$router.push('/home')
				return
			}
			let param = {
				name: this.name,
				info: this.info.filter((item) => {
					return item.trim() != ""
				}),
				pictures: this.pictures,
				manufacturing_date: this.manufacturing_date,
				description: this.description,
				honor: this.honor,
				price: this.price,
				level_id: this.level_id,
				certificate_info: this.certificate_info.filter((item) => {
					return item.pic != "" || item.content != ""
				}),
			}

			let flag = 0

			Object.keys(param).forEach(item => {
				if (JSON.stringify(param[item]) != JSON.stringify(this.beforeZB[item])) {
					console.log(JSON.stringify(param[item]), JSON.stringify(this.beforeZB[item]))
					if ((param[item]) != '') {
						flag = 1
					} else if (item == 'certificate_info') {
						flag = 1
					}
				}
			});
			if (flag == 0) {
				if (!this.hasCG) {

					// this.$router.push(`/guanli?status=${this.status}`)
					return
				}
				// this.$router.push('/home')
				return
			}
			if (this.hasCG) {

				this.postZhenbao(1)
			} else {


			}

		},
		clearData() {
			this.description = ''
			this.picList = []
			this.pictures = []
			this.name = ""
			this.info = [""]
			this.manufacturing_date = ''
			this.honor = ''
			this.price = ''
			this.certificate_info = [{
				pic: "",
				content: "",
			}]
		},
		concan(index) {
			this.certificate_info.splice(index, 1);
			this.load_list.splice(index, 1);
		},
		addCretiPic(index) {
			// 从手机相册中选图

			wx.chooseImage({
				count: 1, // 默认9
				sizeType: ["original"], // 可以指定是原图还是压缩图，默认二者都有
				sourceType: ["album"], // 可以指定来源是相册还是相机，默认二者都有
				success: (res) => {
					this.load_list.splice(index, 1, true)

					wx.getLocalImgData({
						localId: res.localIds[0], // 图片的localID
						success: async (res) => {
							let localData = res.localData;
							if (localData.indexOf(",") == -1) {
								localData = "data:image/jpg;base64," + localData;
							}
							var origImg = new Image();
							var img = new Image();
							img.onload = () => {
								try {
									const canvas = document.createElement("canvas");
									const ctx = canvas.getContext("2d");
									canvas.width = parseInt(img.width / 2);
									canvas.height = parseInt(img.height / 2);
									ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
									const piccc = canvas.toDataURL("image/jpeg", 0.5);
									const images1 = this.dataURLtoBlob(piccc);
									var param1 = new FormData();
									param1.append("file", images1, "test.jpg", {
										headers: {
											"Content-Type": "multipart/form-data",
										},
									});

									setTimeout(() => {
										for (var value of param1.values()) {
											console.log(value);
										}
										this.axios.post("/common/uploadImg", param1).then((res) => {
											this.certificate_info[index].pic = res.data.data.url;
											this.load_list.splice(index, 1, false);
										});
									}, 500)

								} catch (error) {
									console.log(error)
								}

							};
							// origImg.onload = () => {
							// 	const canvas = document.createElement("canvas");
							// 	const ctx = canvas.getContext("2d");
							// 	canvas.width = origImg.width;
							// 	canvas.height = origImg.height;
							// 	ctx.drawImage(origImg, 0, 0, canvas.width, canvas.height);
							// 	const origpic = canvas.toDataURL("image/jpeg", 0.9);
							// 	let images = this.dataURLtoBlob(origpic);
							// 	let param = new FormData();
							// 	param.append("file", images, "test.jpg", {
							// 		headers: {
							// 			"Content-Type": "multipart/form-data",
							// 		},
							// 	});
							// 	this.axios.post("/common/uploadImg", param).then((res) => {
							// 		setTimeout(() => {
							// 			img.src = localData;
							// 		}, 500)
							// 	});

							// }
							img.src = localData;
						},
					});
				},
			});
		},
		checkedFlag(val) {
			this.goRegFlag = !this.goRegFlag

		},
		checkedFlag1(val) {
			this.isKnow = !this.isKnow

		},
		delete_info(index) {
			if (this.info.length > 1) {
				this.info.splice(index, 1)
			} else {
				return
			}

		},

		getPhoto() {
			if (this.picList.length >= 5) {
				return
			}
			let count = this.picList.length
			wx.chooseImage({

				count: 5 - count, // 默认9
				sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
				sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
				success: (res) => {

					var localIds = res.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片


					for (let i = 0; i < localIds.length; i++) {
						this.pictures.push('')
						this.picList.push(
							{
								url: 'http://t.6a.com/87/01193881f683a494.png'.replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol),
								status: 'loading',
								message: this.$t('main.uploading'),
							}
						)
					}
					for (let i = 0; i < localIds.length; i++) {

						wx.getLocalImgData({
							localId: localIds[i], // 图片的localID
							success: (res) => {

								var localData = res.localData; // localData是图片的base64数据，可以用 img 标签显示
								var canvas = document.createElement('canvas')
								var ctx = canvas.getContext('2d')
								var img = new Image();
								if (localData.indexOf(',') == -1) {
									localData = 'data:image/jpg;base64,' + localData
								}
								this.picList[count + i].url = localData
								let sy_logo = document.getElementById('icon_logo')
								let shuiyin = document.getElementById('shuiyin')
								img.onload = () => {
									let image1
									if (img.width > 1920 || img.height > 1920) {
										if (img.width > img.height) {

											let wh = img.width / img.height
											canvas.width = 1920
											canvas.height = 1920 / wh
											ctx.font = '16px PingFang';


											for (let i = 50; i < canvas.height; i += 100) {
												for (let j = 50; j < canvas.width; j += 600) {
													ctx.drawImage(sy_logo, j, i, sy_logo.width / 1.5, sy_logo.height / 1.5);
													ctx.fillText('中国长城文创珍宝中心', j + 160, i + 35);
												}
											}
											let textData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);

											ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

											ctx.globalAlpha = 0.5
											ctx.beginPath();

											ctx.drawImage(shuiyin, (canvas.width - (shuiyin.width) / 1.5), 20, (shuiyin.width) / 1.5, (shuiyin.height) / 1.5);

											ctx.closePath();
											ctx.globalAlpha = 1;
											let originalData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
											this.mergeData(ctx, textData, 'R', originalData)
											let piccc = canvas.toDataURL("image/png")
											image1 = this.dataURLtoBlob(piccc);
										} else {
											let hw = img.height / img.width
											canvas.width = 1920 / hw
											canvas.height = 1920
											ctx.font = '16px PingFang';


											for (let i = 50; i < canvas.height; i += 100) {
												for (let j = 50; j < canvas.width; j += 600) {
													ctx.drawImage(sy_logo, j, i, sy_logo.width / 1.5, sy_logo.height / 1.5);
													ctx.fillText('中国长城文创珍宝中心', j + 160, i + 35);
												}
											}
											let textData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);

											ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
											ctx.globalAlpha = 0.5
											ctx.beginPath();

											ctx.drawImage(shuiyin, (canvas.width - (shuiyin.width) / 1.5), 20, (shuiyin.width) / 1.5, (shuiyin.height) / 1.5);



											ctx.closePath();
											ctx.globalAlpha = 1;
											let originalData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
											this.mergeData(ctx, textData, 'R', originalData)
											let piccc = canvas.toDataURL("image/png")
											image1 = this.dataURLtoBlob(piccc);
										}
									} else {
										canvas.width = img.width
										canvas.height = img.height
										ctx.font = '16px PingFang';


										for (let i = 50; i < canvas.height; i += 100) {
											for (let j = 50; j < canvas.width; j += 600) {
												ctx.drawImage(sy_logo, j, i, sy_logo.width / 1.5, sy_logo.height / 1.5);
												ctx.fillText('中国长城文创珍宝中心', j + 160, i + 35);
											}
										}
										let textData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);

										ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
										ctx.globalAlpha = 0.5
										ctx.beginPath();

										ctx.drawImage(shuiyin, (canvas.width - (shuiyin.width) / 1.5), 20, (shuiyin.width) / 1.5, (shuiyin.height) / 1.5);


										ctx.closePath();
										ctx.globalAlpha = 1;
										let originalData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
										this.mergeData(ctx, textData, 'R', originalData)
										let piccc = canvas.toDataURL("image/png")
										image1 = this.dataURLtoBlob(piccc);
									}

									let param = new FormData();
									param.append("file", image1, 'test.png', {
										headers: {
											"Content-Type": "multipart/form-data",
										},
									});

									this.axios.post("/common/uploadImg", param).then((res) => {
										if (res.data.code == 0) {

											this.pictures[count + i] = res.data.data.url
											this.picList[count + i].status = 'done'


										} else {

											weui.alert(this.$t('main.tpitltu'), { buttons: [{ label: this.$t('main.confIrm') }] })
										}
									})

								}
								img.onerror = () => {
									let image1 = this.dataURLtoBlob(localData);
									let param = new FormData();
									param.append("file", image1, 'test.png', {
										headers: {
											"Content-Type": "multipart/form-data",
										},
									});

									this.axios.post("/common/uploadImg", param).then((res) => {
										if (res.data.code == 0) {
											this.pictures[count + i] = res.data.data.url
											this.picList[count + i].status = 'done'


										} else {
											weui.alert(this.$t('main.tpitltu'), { buttons: [{ label: this.$t('main.confIrm') }] })
										}
									});
								}
								img.src = localData





							}
						})
					}


				}
			});
		},
		mergeData(ctx, textData, color, originalData) {
			var oData = originalData.data;
			var newData = textData.data
			var bit, offset;  // offset的作用是找到结合bit找到对应的A值，即透明度

			switch (color) {
				case 'R':
					bit = 0;
					offset = 3;
					break;
				case 'G':
					bit = 1;
					offset = 2;
					break;
				case 'B':
					bit = 2;
					offset = 1;
					break;
			}

			for (var i = 0; i < oData.length; i++) {
				//此处是为了筛选我们要修改的RGB中那一项，在此处，过滤出来的就是每个坐标点的R值
				if (i % 4 == bit) {

					//我们获取到R值的位置，那对应这个点的A值就是i+offset
					if (newData[i + offset] === 0 && (oData[i] % 2 === 1)) {
						//此处先判断该坐标点的透明度，如果为0，说明这个点是没有水印的，将没有水印信息点的R值变为偶数，并且不能超过0-255的范围
						if (oData[i] === 255) {
							oData[i]--;
						} else {
							oData[i]++;
						}
					} else if (newData[i + offset] !== 0 && (oData[i] % 2 === 0)) {
						//透明度非0，该点有信息，若该点的R值是偶数，将其改为奇数
						oData[i]++;
					}
				}
			}
			//至此，整个图片中所有包含水印信息的点的R值都是奇数，没有水印信息的点的R值都是偶数，再将图片绘入画布，即完成整个水印添加过程
			ctx.putImageData(originalData, 0, 0);
		},
		tijiao() {
			// if (!this.isKnow) {
			// 	weui.alert(this.$t('main.paoaf'))
			// 	return
			// }
			for (let i = 0; i < this.load_list.length; i++) {
				if (this.load_list[i]) {
					weui.alert(this.$t('main.lpssu'), { buttons: [{ label: this.$t('main.confIrm') }] })
					return
				}
			}
			let certificate_info = this.certificate_info.filter((item) => {
				return item.pic || item.content
			})

			for (let i = 0; i < certificate_info.length; i++) {

				if (!certificate_info[i].pic || !certificate_info[i].content) {
					weui.alert(this.$t('main.ceandnameup'), { buttons: [{ label: this.$t('main.confIrm') }] });
					return;
				}
			}
			var infoList = this.info.filter((item) => {
				return item.trim() != ""
			})
			if (!this.name || !infoList[0] || !this.pictures[0] || !this.manufacturing_date || !this.description || !this.price) {

				weui.alert(this.$t('main.iipcfi'), { buttons: [{ label: this.$t('main.confIrm') }] })

			} else {
				if (this.audio_type != 0) {

					if(this.oldData.name != this.name || this.oldData.description != this.description){
						weui.alert(this.$t('main.rzssubmit'), {
							buttons: [{

								label: this.$t('main.cancel'),
								onClick: () => {

								}
							}, {

								label: this.$t('main.determine'),
								type: 'primary',
								onClick: () => {
									setTimeout(() => {
										weui.alert(this.$t('main.aysutosub'), {
											buttons: [{

												label: this.$t('main.cancel'),
												onClick: () => {

												}
											}, {

												label: this.$t('main.determine'),
												type: 'primary',
												onClick: () => {

													this.postZhenbao()
												}
											}]
										})
									}, 500)
								}
							}]
						})
					}else{
						weui.alert(this.$t('main.aysutosub'), {
											buttons: [{

												label: this.$t('main.cancel'),
												onClick: () => {

												}
											}, {

												label: this.$t('main.determine'),
												type: 'primary',
												onClick: () => {

													this.postZhenbao()
												}
											}]
						})
					}
					

					return
				}
				weui.alert(this.$t('main.aysutosub'), {
					buttons: [{

						label: this.$t('main.cancel'),
						onClick: () => {

						}
					}, {

						label: this.$t('main.determine'),
						type: 'primary',
						onClick: () => {

							this.postZhenbao()
						}
					}]
				})

			}
		},
		preview() {
			this.ds_data = {
				name: this.name,
				info: this.info.filter((item) => {
					return item.trim() != ""
				}),
				pictures: this.pictures,
				manufacturing_date: this.manufacturing_date,
				description: this.description,
				honor: this.honor,
				price: this.price,
				level_id: this.level_id,
				level_name: this.level_list.find((item) => { return item.id == this.level_id }).name,
				certificate_info: this.certificate_info.filter((item) => {
					return item.pic
				}),
			}
			this.ds_data.honor = this.ds_data.honor ? this.ds_data.honor : this.ds_data.honor
			localStorage.setItem('ds_data', JSON.stringify(this.ds_data))
			this.$router.push(`/preview?status=0`);
		},
		postZhenbao(index = 0) {

			let param = {
				name: this.name,
				info: this.info.filter((item) => {
					return item.trim() != ""
				}),
				pictures: this.pictures.filter((item) => {
					return item
				}),
				manufacturing_date: this.manufacturing_date,
				description: this.description,
				honor: this.honor,
				price: this.price,
				level_id: this.level_id,
				certificate_info: this.certificate_info.filter((item) => {
					return item.pic != "" || item.content != ""
				}),
			}
			if (index == 1) {
				param.certificate_info = this.certificate_info.filter((item) => {
					return item.pic || item.content
				})
			}


			if (!this.id) {
				this.loading = weui.loading(this.$t('main.loading'))
				if (index == 1) {
					param.type = 1
					Object.keys(param).forEach(item => {
						if (param[item] == '' || param[item] == null) {
							delete param[item]
						}
					});
				} else {
					param.type = 2

				}

				this.axios.post('/goods/store', param).then((res) => {
					if (res.data.code == 0) {
						this.loading.hide()
						if (index == 1) {
							weui.toast(this.draftSasucc, {
								callback: () => {
									// this.$router.push('/home') 
								}
							})
							return
						}
						this.axios.post('/goods/transGoodsInfo', { id: res.data.data.id })
						weui.toast(this.$t('main.trupsuwait'), {
							callback: () => {
								history.replaceState(null, null, '/home')
								this.clearData()
								this.$router.push('/guanli?status=0')
							}
						})
					} else {
						this.loading.hide()
						if (index == 1) {
							// this.$router.push('/home')
							return
						}
						setTimeout(() => {
							if (res.data.msg.indexOf('敏感词') != -1) {
								weui.alert(this.$t('main.submitmg'), { buttons: [{ label: this.$t('main.confIrm') }] })
								let msg = res.data.msg
								this.key_test = msg.split(' ')[0]

								this.mgc = msg.split('：')[1]
								return
							} else if (res.data.msg.indexOf('积分') != -1) {
								weui.alert(this.$t('main.pointsgocz'), {
									buttons: [{

										label: this.$t('main.cancel'),
										onClick: () => {

										}
									}, {

										label: this.$t('main.determine'),
										type: 'primary',
										onClick: () => {
											param.type = 1
											this.axios.post('/goods/store', param).then(() => {
												this.$router.push('/chongzhi?status=1')
											})

										}
									}]
								})
								return
							} else if (res.data.msg.indexOf('存证次数') != -1) {
								weui.alert("无法发布珍宝，当前珍宝存证数量已达上限，是否购买", {
									buttons: [{

										label: this.$t('main.cancel'),
										onClick: () => {

										}
									}, {

										label: this.$t('main.determine'),
										type: 'primary',
										onClick: () => {
											this.auth_mask = true
										}
									}]
								})
								return
							}
							weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
						}, 500)
					}
				})
			} else if (this.status == 1) {
				let flag = 0
				Object.keys(param).forEach(item => {
					if (JSON.stringify(param[item]) != JSON.stringify(this.beforeZB[item])) {
						if (param[item] != '') {
							flag = 1
						} else if (item == 'certificate_info' || item == 'honor') {
							flag = 1
						}
					}

				});
				if (!flag) {
					setTimeout(() => {
						weui.alert(this.$t('main.inm'), { buttons: [{ label: this.$t('main.confIrm') }] })
					}, 500)
					return
				}
				if (index == 1) {
					param.type = 1

				} else {
					param.type = 2
					param.audio_type = 0
				}
				this.loading = weui.loading(this.$t('main.loading'))
				param.goods_id = this.id
				this.axios.post('/goods/edit', param).then((res) => {
					if (res.data.code == 0) {
						this.loading.hide()
						if (index == 1) {
							weui.toast(this.draftSasucc, {
								callback: () => {
									// this.$router.push('/home') 
								}
							})
							return
						}

						this.axios.post('/goods/transGoodsInfo', { id: this.id })
						weui.toast(this.$t('main.treidtwait'), {
							callback: () => {
								history.replaceState(null, null, '/home')
								this.clearData()
								this.$router.push('/guanli?status=0')
							},

						})
					} else {
						this.loading.hide()
						setTimeout(() => {
							if (res.data.msg.indexOf('敏感词') != -1) {
								weui.alert(this.$t('main.submitmg'), { buttons: [{ label: this.$t('main.confIrm') }] })
								let msg = res.data.msg
								this.key_test = msg.split(' ')[0]

								this.mgc = msg.split('：')[1]
								return
							} else if (res.data.msg.indexOf('积分') != -1) {
								weui.alert(this.$t('main.pointsgocz'), {
									buttons: [{

										label: this.$t('main.cancel'),
										onClick: () => {

										}
									}, {

										label: this.$t('main.determine'),
										type: 'primary',
										onClick: () => {
											param.type = 1
											this.axios.post('/goods/edit', param).then(() => {
												this.$router.push('/chongzhi?status=1')
											})
										}
									}]
								})
								return
							}
							weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
						}, 500)

					}
				})
			} else {
				let flag = 0
				Object.keys(param).forEach(item => {
					if (JSON.stringify(param[item]) != JSON.stringify(this.beforeZB[item])) {
						if (param[item] != '') {
							flag = 1
						} else if (item == 'certificate_info' || item == 'honor') {
							flag = 1
						}
					}

				});
				if (this.hasCG) {
					flag = 1
				}
				if (!flag) {
					setTimeout(() => {
						weui.alert(this.$t('main.inm'), { buttons: [{ label: this.$t('main.confIrm') }] })
					}, 500)
					return
				}
				param.id = this.id
				if (index == 1) {
					Object.keys(param).forEach(item => {
						if (param[item] == '' || param[item] == null) {
							delete param[item]
						}
					});
					param.type = 1
				} else {

					param.type = 2
					param.audio_type = 0
				}
				this.loading = weui.loading(this.$t('main.loading'))
				this.axios.post('/goods/edit', param).then((res) => {
					if (res.data.code == 0) {
						this.loading.hide()
						if (index == 1) {
							weui.toast(this.draftSasucc, {
								callback: () => {

									// this.$router.push('/home') 
								}
							})
							return
						}
						this.axios.post('/goods/transGoodsInfo', { id: this.id })
						history.replaceState(null, null, '/home')
						weui.toast(this.$t('main.treidtwait'), {
							callback: () => {

								this.clearData()
								this.$router.push('/guanli?status=0')
							},
						})
					} else {
						this.loading.hide()
						setTimeout(() => {
							if (res.data.msg.indexOf('敏感词') != -1) {
								weui.alert(this.$t('main.submitmg'), { buttons: [{ label: this.$t('main.confIrm') }] })
								let msg = res.data.msg
								this.key_test = msg.split(' ')[0]

								this.mgc = msg.split('：')[1]
								return
							} else if (res.data.msg.indexOf('积分') != -1) {
								weui.alert(this.$t('main.pointsgocz'), {
									buttons: [{

										label: this.$t('main.cancel'),
										onClick: () => {

										}
									}, {

										label: this.$t('main.determine'),
										type: 'primary',
										onClick: () => {
											param.type = 1
											this.axios.post('/goods/edit', param).then(() => {
												this.$router.push('/chongzhi?status=1')
											})
										}
									}]
								})
								return
							}
							weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
						}, 500)

					}
				})
			}


		},
		dataURLtoBlob(dataurl) {
			var arr = dataurl.split(',')
			// 
			var mime = 'image/png'

			var bstr = arr[1] ? atob(arr[1]) : atob(arr[0])
			var n = bstr.length
			var u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new Blob([u8arr], {
				type: mime
			})
		},
		// fanhui(){
		// 	this.disableBrowserBack()
		// },
		addInfo() {
			if (this.info.length < 10) {
				this.info.push('')
			} else {
				weui.alert(this.$t('main.trdatano10'), { buttons: [{ label: this.$t('main.confIrm') }] })
			}


		},
		weDate() {
			weui.datePicker({
				start: 1800,
				end: new Date(),
				defaultValue: [new Date().getFullYear(), new Date().getMonth() + 1, new Date()
					.getDate()], //默认日期

				onConfirm: (result) => {
					//                

					this.manufacturing_date = result[0] + '-' + result[1] + '-' + result[2]
				},
				title: this.$t('main.timechoose'),

			});
		},
		delete_IMG(index) {

			this.pictures.splice(index, 1)
			this.picList.splice(index, 1)

		},
		showContent() {
			Dialog.alert({ message: this.xy.content, confirmButtonColor: '#2d71d7' })
		},
		showHelpContent(key) {
			Dialog.alert({ message: this.help[key], confirmButtonColor: '#2d71d7' })
		},
		changeLevel() {
			if (this.isFrist && this.$route.query.id) {
				let length = this.picker.findIndex((item) => {
					return item.value == this.level_id
				})

				for (let i = 0; i < length; i++) {
					this.picker.shift()
				}
				this.isFrist = false
			}
			weui.picker(this.picker, {
				defaultValue: [this.level_id],
				onConfirm: (res) => {

					this.level_id = res[0].value
				}
			})
		},
		addBtn() {
			this.load_list.push(false)
			this.certificate_info.push({
				pic: "",
				content: "",
			});
		},
		pay_count() {
			weui.alert(`是否支付${this.auth_count_list[this.now_auth_index].ga_money}积分购买${this.auth_count_list[this.now_auth_index].ga_count}个珍宝存证?`, {
				buttons: [
					{
						label: this.$t('main.cancel'),
						onClick: () => {

						}
					},
					{
						label: this.$t('main.confIrm'),
						onClick: () => {
							this.pay_count_post()
						}
					}
				]
			})

		},
		pay_count_post() {
			let id = this.auth_count_list[this.now_auth_index].id
			this.axios.post('/order/payGoodsAuth', { type_id: id, artist_type: 1, is_score: 1 }).then((res) => {
				if (res.data.code == 0) {
					setTimeout(()=>{
						weui.alert('购买成功', {
						buttons: [{
							label: this.$t('main.confIrm'), onClick: () => {
								this.showMask()
								this.axios.post('/artist/detail', { id: localStorage.getItem("art_id") }).then((res) => {
									this.max_auth_count = parseInt(res.data.data.all_auth_count)
									this.now_auth_count = parseInt(res.data.data.auth_count)
								})
							}
						}]
					})
					},500)
				} else {
					setTimeout(()=>{
						weui.alert(this.$t('main.pointsgocz'), {
						buttons: [{

							label: this.$t('main.cancel'),
							onClick: () => {

							}
						}, {

							label: this.$t('main.determine'),
							type: 'primary',
							onClick: () => {
								this.$router.push('/chongzhi?status=1')
							}
						}]
					})
					},500)
				}

			})
		},
		showMask() {
			this.auth_mask = !this.auth_mask
		}

	},
	beforeRouteLeave(to, from, next) {
		if (to.name == 'master' || to.name == 'preview') {
			this.disableBrowserBack()
		}
		next();
	}
};
</script>

<style scoped>
.out {
	padding: 0 0.15rem;
	/* height: 100vh; */
	box-sizing: border-box;
	background: #f4f6f7;
	min-height: 100vh;
	box-sizing: border-box;
	background: url(../assets/img/index_bg.png) no-repeat center;
	background-size: cover;
	background-attachment: fixed;
}

.header {
	display: flex;
	justify-content: space-between;
	/* line-height: 0; */
	margin-bottom: 0rem;
	margin-bottom: 0rem;
	margin: 0 -0.15rem;
	padding: 0 0.15rem;
	align-items: center;
	box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.3);
	padding-bottom: 0.09rem;
	padding-top: 0.05rem;
	background: #ffffff;
}

.Title {
	font-size: 0.16rem;
	position: relative;
}

.English {
	color: #d6eedc;
	position: absolute;
	top: 0.1rem;
	left: 0.15rem;
}

.Chinese {
	color: #1b6a21;
	/* top: -0.2rem; */
	left: 0;
	position: relative;
	text-align: left;
	font-size: 0.18rem;
	white-space: nowrap;
}

.cc-wc {
	font-size: 0.1rem;
	color: #e9630a;
}

.right-icon {
	/* width: 1rem; */
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.right-icon img {
	display: block;
	width: 100%;
	height: 100%;
	width: 0.65rem;
}

.zb-edit {
	font-size: 0.16rem;
	padding-bottom: 0.23rem;
}

.zb-edit-item {}

.sc-btn {
	margin-bottom: 0.15rem;
	display: flex;
	justify-content: space-between;
}

.sc-btn-item {
	width: 0.65rem;
	height: 0.65rem;
	line-height: 0.65rem;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.06rem;
	background: #e5e5e5;
	/* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
	font-size: 0.25rem;
	position: relative;
	color: #919191;
}

:deep(.van-uploader__preview-image) {
	width: 0.65rem;
	height: 0.65rem;
}

:deep(.van-uploader__upload) {
	width: 0.65rem;
	height: 0.65rem;
}

.sc-btn-item img {
	width: 0.6rem;
	height: 0.6rem;
	object-fit: contain;
	display: block;
}

.zb-edit-item-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #9d9d9d;
	margin-bottom: 0.1rem;
	flex-wrap: wrap;
}

.title-ltfe {
	font-size: 0.15rem;
	color: #343434;
	display: flex;
	align-items: center;
}

.title-ltfe img {
	width: 0.18rem;
	margin-right: 0.1rem;
}

.title-right {
	font-size: 0.12rem;
}

.zb-edit-item-bottom {
	position: relative;
	/* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
	border-radius: 6px;
	margin-bottom: 0.15rem;
}

.zb-edit-item-bottom div {
	height: 0.5rem;
	line-height: 0.5rem;
	width: 100%;
	outline: none;

	border-radius: 6px;
	border: none;
	padding-left: 0.1rem;
	box-sizing: border-box;
}

.zb-edit-item-bottom input {
	height: 0.5rem;
	width: 100%;
	outline: none;

	border-radius: 6px;
	border: none;
	padding-left: 0.1rem;
	box-sizing: border-box;
	font-size: 0.15rem;
}

.zb-edit-item-bottom textarea {

	width: 100%;
	outline: none;
	border-radius: 6px;

	border: none;
	padding: 0.1rem;
	box-sizing: border-box;
	resize: none;
	font-size: 0.15rem;
	height: 1.97rem;
	font-family: 'AlibabaPuHuiTiBold';
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	/*WebKit browsers*/

	font-size: 0.14rem;

	color: #cbcbcb;
}

input::-moz-input-placeholder,
textarea::-moz-input-placeholder {
	/*Mozilla Firefox*/

	font-size: 0.14rem;

	color: #cbcbcb;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
	/*Internet Explorer*/

	font-size: 0.14rem;

	color: #cbcbcb;

}

.vis_text {
	width: 100%;
	outline: none;
	margin-bottom: 0.15rem;
	border-radius: 6px;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
	border: none;
	padding: 0.1rem;
	box-sizing: border-box;
	resize: none;
	min-height: 1.97rem;
	font-size: 0.16rem;
	visibility: hidden;
}

.add-input {
	background: #ffffff;
	border-radius: 6px;
	text-align: center;
	height: 0.35rem;
	line-height: 0.35rem;
	cursor: pointer;
}

.agree-box input {
	display: none;
}

.agree-box {
	display: flex;
	align-items: center;
	position: relative;
	background: url(../assets/img/agree-bg.png) no-repeat center;
	background-size: cover;
	margin-top: 0.25rem;
	font-size: 0.14rem;
	height: 0.4rem;
	line-height: 0.4rem;
	color: #fff;
	width: 100%;
	box-sizing: border-box;
	padding-left: 0.175rem;
}

/* .agree-box label::before{
	   display: inline-block;
	       content: "";
	       width: 0.175rem;
	       height: 0.175rem;
		   background-color: #ffffff;
		   border-radius: 50%;
	       left: 0;
	       top: 50%;
	       transform: translateY(-50%);
	       position: absolute;
		   border: 1px solid #bfbfbf;
		   box-sizing: border-box;
	}
	.agree-box input:checked+label::before{
	   display: inline-block;
	       content: "";
	       width: 0.175rem;
	       height: 0.175rem;
	       border-radius: 50%;
	       position: absolute;
		   left: 0;
	       top: 50%;
	       transform: translateY(-50%);
	       background: url(../assets/img/rz-select-iocn.png) no-repeat center;
		   background-size: cover;
	   
	
	} */
.agree-box1 input {
	display: none;
}

.agree-box1 {
	display: flex;
	align-items: center;
	position: relative;
	margin-top: 0.2rem;
}

.agree-box1 label::before {
	display: inline-block;
	content: "";
	width: 0.175rem;
	height: 0.175rem;
	background-color: #ffffff;
	border-radius: 50%;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
	border: 1px solid #bfbfbf;
	box-sizing: border-box;
}

.agree-box1 input:checked+label::before {
	display: inline-block;
	content: "";
	width: 0.175rem;
	height: 0.175rem;
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	background: url(../assets/img/rz-select-iocn.png) no-repeat center;
	background-size: 100% 100%;
}

.agree-box1 label {
	font-size: 0.12rem;
	margin-left: 0.325rem;
}

.agree-box label {
	font-size: 0.14rem;
	font-weight: 500;
	letter-spacing: 0px;
	color: #ffffff;
	margin-left: 0.325rem;
	height: 0.4rem;
	line-height: 0.4rem;
	flex-grow: 1;
	background: url(../assets/img/agree-bg.png) no-repeat center;
	border-radius: 0.05rem;
	padding-left: 0.175rem;
	line-height: 0.175rem;
}

.yulan_btn {
	width: 1.03rem;
	height: 0.35rem;
	line-height: 0.35rem;
	text-align: center;
	color: #333;
	border-radius: 0.05rem;
	background: #fee69b;
	margin-top: 0.36rem;
	margin-bottom: 0.3rem;
}

.submint-info {
	width: 2.3rem;
	height: 0.35rem;
	line-height: 0.35rem;
	text-align: center;
	color: #333;
	background: url(../assets/img/view-more.png) no-repeat;
	background-size: 100% 100%;
	margin-top: 0.36rem;
	margin-bottom: 0.3rem;
}

.delete {
	position: absolute;
	width: 0.16rem;
	height: 0.16rem;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.12rem;
	background: #FA5151;
	color: #FFFFFF;
	top: -0.08rem;
	right: -0.08rem;
	z-index: 1;
}

.rmb-icon-box {
	position: relative;
}

.red {
	color: #fe3444;
	margin-left: 0.04rem;
}

.rmb-icon {
	position: absolute;
	left: 0.2rem;
	top: 50%;
	transform: translate(-50%, -50%);
}

.rmb-icon-box input {
	padding-left: 0.4rem;
	height: 0.4rem;
}

.zb-edit-item-bottom .add-input {
	color: #2d71d7;
	font-size: 0.15rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 0;
	line-height: normal;
}

.add-input .add-icon {
	font-size: 0.25rem;
	margin-right: 0.125rem;
}

.blue_xy {
	color: #2d71d7;
	font-size: 0.12rem;
	line-height: 0.175rem;
}

.picker_img {
	position: absolute;
	width: 0.06rem;
	height: 0.12rem;
	right: 0.15rem;
	transform: rotate(180deg);
}

.err {
	color: red;
	font-size: 0.15rem;
	margin-bottom: 0.15rem;
}

.err1 {
	color: red;
	font-size: 0.15rem;
	margin-top: 0.15rem;
}

.delete_e {
	box-shadow: 0 0 0 0.01rem red;
	width: 0.2rem !important;
	height: 0.2rem !important;
	border-radius: 50% !important;
	position: absolute;
	color: red;
	font-size: 0.15rem;
	line-height: 0.15rem !important;
	right: 0.15rem;
	padding: 0 !important;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0.15rem;
}

.loading_i {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #1989fa;
	font-size: 0.12rem;
	background: rgba(0, 0, 0, 0.6);
}

:deep(.van-loading__text) {
	font-size: 0.12rem;
	line-height: 0.12rem;
	margin: 0;
	margin-top: 0.1rem;
}

.question {
	width: 0.15rem !important;
	height: 0.15rem !important;
}

.question1 {
	width: 0.15rem !important;
	height: 0.15rem !important;
	/* position: absolute; */
	left: 0.02rem;
	margin-right: 0.1rem;
}

/* 证书上传图片 */
.creti-box {
	position: relative;
}

.zs-title {
	font-size: 0.14rem;
}

.concan {
	position: absolute;
	right: 0.05rem;
	top: 0;
	width: 24px;
	height: 24px;
}

.certi-add-btn {
	width: 0.65rem;
	height: 0.65rem;
	overflow: hidden;
	background-color: white;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	border-radius: 0.05rem;
	margin: 0.06rem 0 0.10rem;
}

.certi-add-btn img {
	width: 100%;
}

.certi-add-btn .add-btn-icon {
	font-size: 0.3rem;
	color: #CCCCCC;
}

.certi-add-btn .loading_icon {
	position: absolute;
	width: 0.65rem;
	height: 0.65rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #1989fa;
	font-size: 0.12rem;
	background: rgba(0, 0, 0, 0.6);
}

.zb-edit-item-content {
	/* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
	border-radius: 6px;
	margin-bottom: 0.12rem;
}

.zb-edit-item-content input {
	height: 0.5rem;
	width: 100%;
	outline: none;

	border-radius: 6px;

	border: none;
	padding-left: 0.1rem;
	/* padding-top: 0.1rem; */
	box-sizing: border-box;
	color: #545454;
	font-size: 0.14rem;
}

.certi-add-btn .zb-edit-item-content textarea {
	width: 100%;
	outline: none;
	margin-bottom: 0.15rem;
	border-radius: 6px;

	border: none;
	padding: 0.1rem;
	box-sizing: border-box;
	resize: none;
	height: 1.97rem;
	font-size: 0.14rem;
	color: #545454;
	font-family: 'AlibabaPuHuiTiBold';
}

.add-btn {
	background-color: white;
	color: #2d71d7;
	width: 100%;
	height: 0.45rem;
	border: 0.01rem solid #2d71d7;
	border-radius: 0.05rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 0;
	line-height: normal;
	margin-bottom: 0.2rem;
}

.add-btn .add-icon {
	font-size: 0.25rem;
	margin-right: 0.05rem;
}

.tips {
	margin: 0 auto;
	margin-top: 0.21rem;
	width: 3.4rem;
	height: 0.50rem;
	background: rgba(96, 25, 37, 0.4);
	box-shadow: inset 0 0 0 1px #601925;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.12rem;
	color: #fff;
	border-radius: 0.03rem;
}

.tips img {
	width: 0.17rem;
	height: 0.17rem;
	margin-right: 0.145rem;
	margin-left: 0.175rem;
}

.tips div {
	margin-right: 0.06rem;
}

.tips span {
	color: #fae44c;
}

.zb_count {
	margin: 0 -0.15rem;
	background: url(../assets/img/count_bg.png) no-repeat;
	background-size: 100% 100%;
	height: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 0 0.15rem;
	margin-bottom: 0.26rem;
}

.zb_count .left {
	font-size: 0.13rem;
	color: #333333;
}

.zb_count .left span {
	font-size: 0.175rem;
	color: #851616;
}

.zb_count .buy_count {
	padding: 0 0.2rem;
	height: 0.3rem;
	box-sizing: border-box;
	background: linear-gradient(to bottom, #c75e62, #7a1414);
	border-radius: 0.15rem;
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 0.15rem;
}

.count_mask {
	left: 0;
	top: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: flex-end;
	box-sizing: border-box;
	z-index: 9;
}

.count_pop {
	background: url(../assets/img/apply_from.png) no-repeat;
	background-size: 100% 100%;
	height: 3.18rem;
	width: 100%;
	position: relative;
	font-size: 0.15rem;
	color: #ffebcc;
}

.count_pop .title {
	margin-top: 0.25rem;
	padding: 0 0.2rem;

}

.count_pop .count_list {
	margin-top: 0.25rem;
	display: flex;
	overflow-x: auto;
}

.count_items {
	flex-shrink: 0;
	margin-left: 0.2rem;
	width: 1rem;
	height: 1.1rem;
	background: url(../assets/img/count_items.png) no-repeat;
	background-size: 100% 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #333;
	font-size: 0.12rem;
	opacity: 0.5;
}

.count_items.active {
	opacity: 1;
}

.count_items .num {
	margin-top: 0.12rem;
}

.count_items .price {
	margin-top: 0.12rem;
}

.count_items .price span {
	font-size: 0.175rem;
}

.count_items .count {
	margin-top: 0.15rem;
	color: #606060;
	font-size: 0.1rem;
}

.count_tips {
	margin-top: 0.15rem;
	padding: 0 0.2rem;
}

.count_bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 0.8rem;
	background: #601925;
	width: 100%;
	box-shadow: 0 1px 10px rgb(32, 32, 32);
	display: flex;
	justify-content: center;
}

.count_button {
	margin-top: 0.11rem;
	width: 3.39rem;
	height: 0.5rem;
	background: #fff1cb;
	border-radius: 0.08rem;
	display: flex;
	justify-content: center;
	align-items: center;
	/* font-weight: bold; */
}

.count_button div:nth-child(1) {
	font-size: 0.22rem;
	color: #101010;
}

.count_button div:nth-child(1) span {
	font-size: 0.12rem;
}

.count_button div:nth-child(2) {
	margin-left: 0.2rem;
	font-size: 0.15rem;
	color: #333333;
}</style>
